import * as React from "react"
import LayoutEn from "../../components/layoutEn"

const PrivacyPage = () => {
  return (
    <LayoutEn pageTitle="Privacy Policy">
      <article className="nomal-page">
        <h1>Privacy Policy</h1>
        <section>
          <br/>
          <p>We, Horiko Capital Management LLC, are committed to keeping nonpublic personal information about you secure and confidential. This notice is intended to help you understand how we fulfill this commitment.</p>
          <br/>
          <p>From time to time, we may collect a variety of personal information about you, including:</p>
          <ul>
            <li>Information we receive from you on applications and forms, via the telephone, and through our web sites; and</li>
            <li>Information about your transactions with us, our affiliates, or others (such as your purchases, sales, or account balances).</li>
          </ul>
          <br/>
          <p>We do not disclose your nonpublic personal information, except as permitted by applicable law or regulation. For example, we may share this information with others in order to process your transactions. We may also provide this information to companies that perform services on our behalf, such as printing and mailing, or to other financial institutions with whom we have agreements. We will require these companies to protect the confidentiality of this information and to use it only to perform the services for which we hired them.</p>
          <br/>
          <p>With respect to our internal security procedures, we maintain physical, electronic, and procedural safeguards to protect your nonpublic personal information, and we restrict access to this information.</p>
          <br/>
          <p>If you decide at some point either to close your account(s) or become an inactive customer, we will continue to adhere to our privacy policies and practices with respect to your nonpublic personal information.</p>
          <br />
        </section>
      </article>
    </LayoutEn>
  )
}

export default PrivacyPage
