import React, {useState} from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';
import {
  container,
  logo,
  nav,
  navContainer,
  navLinks,
  toggleNav,
  toggleNavActive,
  navLinksActive,
  navLinkItem,
  navLinkText,
  navLinksF,
  navLinkItemF,
  navLinkTextF,
  footerContents,
  copyright
} from "./layout.module.css";

const LayoutEn = ({ pageTitle, children }) => {
  const [navbar, setNavbar] = useState(false);

  const showNavbar = () => setNavbar(!navbar);

  return (
    <div className={container}>
      <title>{pageTitle}</title>
      <header>
        <nav className={navContainer}>
          <div className={nav}>
            <div>
              <Link to="/en">
                <StaticImage src="../images/company_logo.png" alt="Horiko Capital Management" className={logo}/>
              </Link>
            </div>
            <button className={navbar ? toggleNavActive : toggleNav} aria-label={navbar ? "Close Nav" : "Open Nav"} onClick={showNavbar}>
              <span></span>
              <span></span>
              <span></span>
            </button>
            <ul className={navbar ? navLinksActive : navLinks}>
              <li className={navLinkItem}>
                <Link to="/en" className={navLinkText} onClick={showNavbar}>Home</Link>
              </li>
              <li className={navLinkItem}>
                <Link to="/en/#About" className={navLinkText} onClick={showNavbar}>About</Link>
              </li>
              <li className={navLinkItem}>
                <Link to="/en/#Team" className={navLinkText} onClick={showNavbar}>Team</Link>
              </li>
              <li className={navLinkItem}>
                <Link to="/en/#Contact" className={navLinkText} onClick={showNavbar}>Contact</Link>
              </li>
              <li className={navLinkItem}>
                <Link to="/" className={navLinkText}>日本語</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <main>
        {children}
      </main>
      <footer>
        <div className={footerContents}>
          <ul className={navLinksF}>
            <li className={navLinkItemF}>
              <a href="https://www.facebook.com/horikocapital/" target="_blank" rel="noreferrer" className={navLinkTextF}>Facebook</a>
            </li>
            <li className={navLinkItemF}>
              <a href="https://twitter.com/horikocapital" target="_blank" rel="noreferrer" className={navLinkTextF}>Twitter</a>
            </li>
          </ul>
          <ul className={navLinksF}>
            <li className={navLinkItemF}>
              <Link to="/en/legal" className={navLinkTextF}>Legal Disclaimer</Link>
            </li>
            <li className={navLinkItemF}>
              <Link to="/en/privacy" className={navLinkTextF}>Privacy Policy</Link>
            </li>
          </ul>
          <p className={copyright}>Ⓒ 2021 HORIKO CAPITAL MANAGEMENT LLC</p>
        </div>
      </footer>
    </div>
  )
}

export default LayoutEn
